import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';
import 'sweetalert2/dist/sweetalert2.min.css';

import 'primevue/resources/themes/md-light-indigo/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'


/* Theme variables */
import './theme/variables.css';

import store from './store'

/* Event bus */
import mitt from 'mitt';
const emitter = mitt();

/* I18N */
import useI18nAffidaty from './composable/I18nAffidatyApi';

/* Prime vue table */
import PrimeVue from 'primevue/config';

import '@/utils/string'
import { initNumberFormat } from '@marchintosh94/number-format';
import VueGtag from "vue-gtag";


initNumberFormat({
  defaultErrorValue: 0,
  defaultDecimals: 2,
  delimitersChar: {
    decimal: ',',
    thousands: '.'
  },
  defaultCurrecySymbol: {sign: '€', code: 'EUR'}
})
/* ----- */
/**
 * Auth in dynamic import based on env
 */
const authinEsm = document.createElement('script')
authinEsm.setAttribute('type', `module`)
authinEsm.setAttribute('data-stencil', "")
authinEsm.setAttribute('data-resources-url', "/build/")
authinEsm.setAttribute('data-stencil-namespace', "affidaty-auth-in")
authinEsm.setAttribute('src', `${process.env.VUE_APP_AUTHIN_CDN || ""}/affidaty-auth-in.esm.js`)
document.head.appendChild(authinEsm)
const authinJs = document.createElement('script')
authinJs.setAttribute('data-stencil', "")
authinJs.setAttribute('nomodule', "")
authinJs.setAttribute('src', `${process.env.VUE_APP_AUTHIN_CDN || ""}/affidaty-auth-in.js`)
document.head.appendChild(authinJs)

const app = createApp(App)
.use(IonicVue, { rippleEffect: false, animated: false, })
.use(store)
.use(IonicVue)
.use(useI18nAffidaty().i18n)
.use(PrimeVue)
.use(VueGtag, {
  appName: 'Synkrony',
  pageTrackerScreenviewEnabled: true,
  enabled: true,
  bootstrap: true,
/*   pageTrackerExcludedRoutes: [
    '/admin', 
    '/admin/dashboard',
    '/admin/escrow',
    '/admin/withdraw',
  ], */
  config: { 
    id: "G-7GHW4QVPJ8",
  },
}, router)
.use(router);

app.config.globalProperties.emitter = emitter;

app.provide("emitter", app.config.globalProperties.emitter)

const resetTimeotCallback = () => setTimeout(() => {
    window.AuthIn4rya.logout().then(() => document.location.href = "/")
  }, Number(process.env.VUE_APP_TIMEOUT_LOGOUT) * 60 * 1000); // 5 min 


let timeoutLogin: any = null

router.isReady().then(() => {
  app.mount('#app')
  timeoutLogin = resetTimeotCallback()

  window.onkeydown = () => {
    clearTimeout(timeoutLogin)
    timeoutLogin = resetTimeotCallback()
  }
  window.onmousemove = () => {
    clearTimeout(timeoutLogin)
    timeoutLogin = resetTimeotCallback()
  }
});

if (window.location.hostname !== process.env.VUE_APP_HOSTNAME){
  window.location.href = `https://${process.env.VUE_APP_HOSTNAME}`
}

if ((window as any).Cypress){
  (window as any).store = store
}