
import useI18nAffidaty from "@/composable/I18nAffidatyApi";
import { defineComponent, inject, PropType, ref, toRefs } from 'vue'
import {
  IonList,
  IonLabel,
  IonNote,
  IonToolbar,
  IonButton,
  IonTitle,
  IonHeader,
  IonContent,
  IonItem,
  IonIcon,
  IonAvatar,
  IonText
} from '@ionic/vue';
import {checkmarkCircle, closeCircle, copyOutline} from 'ionicons/icons'
import { OperationConfirmStatus } from "@/models/types";
import { useStore } from "vuex";
import { onCopyClipboardWithAlert } from "../utils/common";
import moment from "moment";
import useSweetAlertApi from "@/composable/SweetAlertApi";
const operationConfirmProps = {
	title: {
		type: String,
		default: ""
	},
	idTicket: {
		type: String,
		default: ""
	},
	idTicketFee: {
		type: String,
		default: ""
	},
	status: {
		type: String as PropType<OperationConfirmStatus>,
		default: ""
	},
	buyAssetName: {
		type: String,
		default: ""
	},
	buyAssetQuantity: {
		type: String,
		default: ""
	},
	buyPriceAmount: {
		type: String,
		default: ""
	},
	buyFeeAmount: {
		type: String,
		default: ""
	},
	buyTotalPaid: {
		type: String,
		default: ""
	},
	changeAssetNameSell: {
		type: String,
		default: ""
	},
	changeAssetSellQuantity: {
		type: String,
		default: ""
	},
	changeAssetNameAccepted: {
		type: String,
		default: ""
	},
	changeTotalFee: {
		type: String,
		default: ""
	},
	changeTotalAmount: {
		type: String,
		default: ""
	},
	withdrawAssetName: {
		type: String,
		default: ""
	},
	withdrawAmount: {
		type: String,
		default: ""
	},
	withdrawFee: {
		type: String,
		default: ""
	},
	withdrawTotal: {
		type: String,
		default: ""
	},
	abortAssetName: {
		type: String,
		default: ""
	},
	abortAssetAmount: {
		type: String,
		default: ""
	},
	abortFee: {
		type: String,
		default: ""
	},
	alertMessage: {
		type: String,
		default: ""
	},
	txDetailCreatedAt: {
		type: String,
		default: ""
	},
	txDetailType: {
		type: String,
		default: ""
	},
	txDetailAsset: {
		type: String,
		default: ""
	},
	txDetailAssetQuantity: {
		type: String,
		default: ""
	},
	transactionListTitle: {
		type: String,
		default: ""
	}
}
export default defineComponent({
	components: {
		IonList,
		IonLabel,
		IonNote,
		IonToolbar,
		IonButton,
		IonTitle,
		IonHeader,
		IonContent,
		IonItem,
		IonIcon,
		IonAvatar,
		IonText
	},
	props: operationConfirmProps,
	emits: ["onCloseOperationConfirm"],
	setup(props, {emit}) {
		const { t } = useI18nAffidaty()
		const { fireConfirmMessage } = useSweetAlertApi()
		const properties = toRefs(props)
		const store = useStore()
		const onClose = () => {
			emit("onCloseOperationConfirm")
		}
		const onCopyToClipboard = (value: string) => {
			onCopyClipboardWithAlert(value, t('Copied to clipboard'))
		}
		const operationStatus = ref<string>("")
		const operationMessage = ref("")
		const operationMessageType = ref("")

		const openTooltip = (value: string) =>{
			fireConfirmMessage('info', {
				title: t('Transaction ID'),
				html: `<p>${value}</p>`,
				confirmButtonText: 'Ok',
				showCancelButton: false
			})
		}
		
		switch(properties.status.value){
			case "SUCCESS":
				operationStatus.value = "COMPLETED"
				operationMessageType.value = "success"
				operationMessage.value = t("Congratulation! The operation has been successfully completed!")
				break
			case "PENDING-BUY-BANKTRANSFER":
				operationStatus.value = "PENDING"
				operationMessageType.value = "primary"
				operationMessage.value = t("Hi, we have received your purchase request via bank transfer. You will find the payment details and the transaction hash which will be entered as purpose. Check your email to complete your purchase!")
				break
			case "PENDING-WITHDRAW":
				operationStatus.value = "PENDING"
				operationMessageType.value = "primary"
				operationMessage.value = t("Congratulation! The operation has been successfully completed!")
				break
			case "TRANSACTION_DETAIL":
				operationStatus.value = ""
				operationMessage.value = ""
				break
			case "ERROR":
				operationStatus.value = "ERROR"
				operationMessageType.value = "danger"
				operationMessage.value = t("Oh no! Something went wrong during the operation!")
				break
			default:
				operationStatus.value = ""
				operationMessage.value = ""
		}
		
		return {
			t,
			onClose,
			...properties,
			date: moment().locale(store.state.language).format("L LT"),
			icons: {
				copyOutline,
				closeCircle,
				checkmarkCircle
			},
			onCopyToClipboard,
			operationStatus,
			operationMessage,
			operationMessageType,
			openTooltip
		}
	}
})
