import axios from "axios";
import { Ref, ref } from "vue";
import { createI18n, I18nOptions, IntlDateTimeFormat, IntlNumberFormat, LocaleMessageDictionary, VueMessageType } from "vue-i18n";


export enum Locales {
    EN = 'en-US'
}

interface I18nProperties {
    message: LocaleMessageDictionary<VueMessageType>;
    dateFormat: IntlDateTimeFormat;
    numberFormat: IntlNumberFormat;
}

const defaultLocale = Locales.EN
const i18n = createI18n({
    legacy: false,
    locale: defaultLocale,
    fallbackLocale: defaultLocale,
    // lazy loaded
    messages: {},
    // lazy loaded
    datetimeFormats: {},
    // lazy loaded
    numberFormats: {}
} as I18nOptions)

const isLoadingLanguage: Ref<boolean> = ref<boolean>(false)
export default function useI18nAffidaty() {

    const setI18nLanguage = (locale: string): string => {
        if (i18n.mode === 'legacy') {
            i18n.global.locale = locale
        } else {
            (i18n.global.locale as any).value = locale
        }
        //document.querySelector('html')?.setAttribute('lang', locale)
        return locale
    }

    const loadLocaleMessages = (locale: string, apiUrl: string): Promise<string> => {
        if (!i18n.global.availableLocales.includes(locale)){
            if (!isLoadingLanguage.value){
                isLoadingLanguage.value = true
                // load locale messages with dynamic import
                return axios.get<LocaleMessageDictionary<VueMessageType>>(
                    apiUrl
                ).then(response => {
                    i18n.global.setLocaleMessage(locale, response.data)
                    // i18n.global.setDateTimeFormat(locale, response.data.dateFormat)
                    // i18n.global.setNumberFormat(locale, response.data.numberFormat)
                    setI18nLanguage(locale)
                    return Promise.resolve(locale)
                })
                .finally(() => {
                    isLoadingLanguage.value = false
                })
            }
        } else if (
            (i18n.mode === 'legacy' && i18n.global.locale !== locale) ||
            (i18n.mode !== 'legacy' && (i18n.global.locale as any).value !== locale)) {
            setI18nLanguage(locale)
            isLoadingLanguage.value = false
        }

        return Promise.resolve(locale)
    }

    return {
        i18n,
        defaultLocale,
        setI18nLanguage,
        loadLocaleMessages,
        t: i18n.global.t,
        // d: i18n.global.d,
        // n: i18n.global.n,
    }
}
