
import {
	IonApp,
	IonRouterOutlet,
	IonHeader,
	IonIcon,
	IonLabel,
	IonContent,
	IonMenu,
	IonSplitPane,
	IonLoading,
	IonModal,
	IonCol,
	IonRow,
	menuController,
	IonFooter
} from "@ionic/vue";
import { computed, defineComponent, ref, watch } from "vue";
import { useStore } from "vuex";
import useI18nAffidaty from '@/composable/I18nAffidatyApi';
import AuthInComponent from'@/lib/authin-wrapper/vue/AuthInComponent.vue'
import DynamicModal from '@/components/DynamicModal.vue'
import { RootStore } from "./store";
import { Menu, OperationConfirmProps, User } from "./models/types";
import { Emitter, EventType } from "mitt";
import useSweetAlertApi from "./composable/SweetAlertApi";
import { AlertMessage } from './store/modules/uiModule'
import useAuth from "./composable/AuthApi";
import { logoutApplication, isEmptyOrNull } from "./utils/common";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
	name: "App",
	components: {
		IonApp,
		IonRouterOutlet,
		IonHeader,
		IonIcon,
		IonLabel,
		IonContent,
		IonMenu,
		IonSplitPane,
		IonLoading,
		AuthInComponent,
		DynamicModal,
		IonModal,
		IonCol,
		IonRow,
		IonFooter
	},
	data() {
		return {
			hasAlert: false,
			alertMessage: "",
		}
	},
	setup() {
		const { t } = useI18nAffidaty()
		const router = useRouter()
		const route = useRoute()
		const store = useStore<RootStore>()
		const activeItem = ref<number|undefined>(undefined)
		const isLoading = computed(() => store.getters["ui/showLoader"])
		const language = computed(() => store.getters.language)
		const menu = ref<Menu[]>([])
		const isAuthenticated = computed(() => store.getters['auth/isAuthenticated'])
		const userData = computed<{fullName: string; ns: string}>(() => {
			const user: User = store.getters.userInformations
			const userHasInfo = !!user?.userInfo?.name && !!user?.userInfo?.surname
			return {
				fullName: userHasInfo ? `${user.userInfo.name} ${user.userInfo.surname}` : user.userInfo.email,
				ns: userHasInfo ? `${user.userInfo.name.substring(0,1).toUpperCase()}${user.userInfo.surname.substring(0, 1).toUpperCase()}` : `${user.userInfo.email.substring(0, 1).toUpperCase()}${user.userInfo.email.substring(1, 2).toUpperCase()}` 
			}
		})
		const { fireAlertMessage } = useSweetAlertApi()
		
		const authReady = () => {
			window.AuthIn4rya.isValidVersion().then(res => res? null : logoutApplication())
		}

    const closeMenu = () => menuController.close()

		const selectItem = (i: number, route: Menu) => {
			activeItem.value = i
      closeMenu()
			route.navigationType == 'push'? router.push(route.path) : router.replace(route.path)
		}

		setInterval(() => {
			if(isAuthenticated.value){
				store.dispatch('externalCrypto/getCryptoCoins')
			}
		}, 60000)

		const operationConfirm = computed<OperationConfirmProps>(() => store.getters.operationConfirm)
		const balanceParams = {
			publicKey: store.state.userInformations? store.state.userInformations!.publicKey : "",
			account: store.state.account
		}
		const clearOperationConfirm = () => {
			store.commit("setOperationConfirm", {})
			localStorage.removeItem("orderConfirm")
			store.dispatch('getBalance', balanceParams)
			store.dispatch('getTransactionList', balanceParams)
		}

		watch(computed<AlertMessage[]>(() => store.getters["ui/alertMessage"]), (values, oldValues) => {
			if (values){
				const messages = values.filter(newval => !oldValues.some(old => old.message === newval.message))
				messages.forEach((msg, i) => {
					fireAlertMessage(msg.type, {
						title: msg.title,
						text: msg.message,
						position: "top-end"
					}).finally(() => store.commit("ui/removeAlertMessage", i))
				})
			}
		})
		
		const initMenu = () => {
			const {isAuthorizedRole} = useAuth(store.getters["auth/permissions"])
			menu.value = [
				{
					label: "DASHBOARD",
					icon: "",
					path: "/dashboard",
					visible: true,
					navigationType: 'push'
				},
				{
					label: (t as (key: string, plural: number) => string)("Transaction", 2).toUpperCase(),
					icon: "",
					path: "/transactions",
					visible: true,
					navigationType: 'push'
				},
				{
					label: t("Marketplace").toUpperCase(),
					icon: "",
					path: "/marketplace",
					visible: false,
					navigationType: 'push'
				},
				{
					label: t("NFT").toUpperCase(),
					icon: "",
					path: "/nft",
					visible: true,
					navigationType: 'push'
				},
				{
					label: t("Admin").toUpperCase(),
					icon: "",
					path: "/admin",
					visible: isAuthorizedRole("admin"),
					navigationType: 'push'
				}
			]
		}

		watch(isAuthenticated, (value) => {
			if(value){
				initMenu()
				store.dispatch('getAvailableAssets')
				store.dispatch('getBalance', balanceParams)
			}
		}, {immediate: true})

		watch(language, () => {
			initMenu()
		})

		return {
			menu,
			t,
			language: computed(() => store.getters.language),
			authinApplication: process.env.VUE_APP_AUTHIN_APPLICATION,
			authinApikey: process.env.VUE_APP_AUTHIN_APIKEY as string,
			env: process.env.VUE_APP_ENVIRONMENT as "dev" | "prod" | "staging",
			authReady,
			activeItem,
			selectItem,
			isAuthenticated,
			operationConfirm,
			clearOperationConfirm,
			closeMenu,
			isLoading,
			isEmptyOrNull,
			route,
			userData
		};
	},
});
