import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_affidaty_sign = _resolveComponent("affidaty-sign")!

  return (_openBlock(), _createBlock(_component_affidaty_sign, _mergeProps({
    cid: "sign-button",
    ref: "authIn"
  }, {
      language: _ctx.prop.lang,
      label: _ctx.prop.buttonLabel,
      ..._ctx.prop
    }, {
    "button-class": _ctx.prop.buttonClass,
    "button-image-class": _ctx.prop.buttonImageClass,
    "button-subtitle-class": _ctx.prop.buttonSubtitleClass,
    "button-title-class": _ctx.prop.buttonTitleClass,
    style: `${_ctx.prop.mode == 'ghost'? 'height:0;width:0;position:absolute;left:-9999px;visibility:hidden;' : _ctx.prop.style}`
  }), null, 16, ["button-class", "button-image-class", "button-subtitle-class", "button-title-class", "style"]))
}