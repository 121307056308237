import useI18nAffidaty from "@/composable/I18nAffidatyApi";
import { http } from "@/interceptor/http.interceptor";
import { ExchangeApi, ExchangeOption, MarketplaceResposeData } from "@/models/marketplace-types";
import { ApiResponse, AssetInterface, BalanceAssetInterface } from "@/models/types";
import { convertvalueFromBc, formatNumberWithCustomDecimal } from "@/utils/common";
import { Emitter, EventType } from "mitt";
import { ActionContext, Module } from "vuex";
import { RootStore } from "..";
import { AlertMessage } from "./uiModule";

export interface MarketModule {
    assets: AssetInterface[];
    exchangeOptions: ExchangeOption[];
    displayLoadMore: boolean;
    marketFilters: MarketFilters;
    itemPerPage: number;
}

export interface MarketFilters {
    asset: Pick<AssetInterface, 'id' | 'name'>;
    myExchanges: boolean;
    exchangesClosed: boolean;
    assetType: Pick<AssetInterface, 'id' | 'name'>;
}

export type FilterExchangesPayload = {
    key: string;
    value: any;
}

const {t} = useI18nAffidaty()


const mapFromExchangeApi = (ex: ExchangeApi, balance: BalanceAssetInterface[], assets: AssetInterface[]): (ExchangeOption & {balance?: BalanceAssetInterface})[] => Object.keys(ex.assets).map(key => {
    const optionAsset = assets.find(a => a.id === key) 
    const exchangeAsset = assets.find(a => a.id === ex.asset)
    const feeAsset = assets.find(a => a.sign === ex.fee.asset)
    const penaltyAsset = assets.find(a => a.id === ex.penaltyFee.asset)
    
    return {
        asset: key,
        amount: ex.assets[key].conversionRate * ex.amount,
        displayedAmount: formatNumberWithCustomDecimal(ex.assets[key].conversionRate * ex.amount, optionAsset? optionAsset.decimal : 2),
        conversionRate: ex.assets[key].conversionRate,
        assetName: optionAsset? optionAsset.name : "",
        assetCode: optionAsset? optionAsset.sign : "",
        assetDecimal: optionAsset? optionAsset.decimal : undefined,
        exchange: {
            ...ex, 
            assetName: (exchangeAsset? exchangeAsset.name : ""), 
            assetCode: (exchangeAsset? exchangeAsset.sign : ""),
            assetDecimal: exchangeAsset? exchangeAsset.decimal : undefined,
            fee: {
                ...ex.fee, 
                sign: feeAsset? feeAsset.sign : "", 
                decimal: feeAsset? feeAsset.decimal : undefined,
            },
            penaltyFee:{
                ...ex.penaltyFee,
                sign: penaltyAsset? penaltyAsset.sign : "", 
                decimal: penaltyAsset? penaltyAsset.decimal : undefined
            },
            displayedAmount: formatNumberWithCustomDecimal(ex.amount, exchangeAsset? exchangeAsset.decimal : 2),
        },
        balance: balance.find(b => b.id === key)
    }
})

const sortExchanges = (firstOption: (ExchangeOption & {balance?: BalanceAssetInterface}), secondOption: (ExchangeOption & {balance?: BalanceAssetInterface})) => {
    if (!firstOption.balance && secondOption.balance){
        return 1
    }
    if (firstOption.balance && !secondOption.balance){
        return -1
    }
    if (firstOption.balance && secondOption.balance){
        const firstAmountDiff = firstOption.balance.amount - firstOption.amount
        const secondAmountDiff = secondOption.balance.amount - secondOption.amount
        //se sono entrambi maggiori o uguali a 0 o entrambi minori
        if ((firstAmountDiff >= 0 && secondAmountDiff >= 0) || (firstAmountDiff < 0 &&  secondAmountDiff < 0)){
            //nel caso in cui siano < 0 allora ritorno prima l'elemento più vicino allo 0 e quindi al balance dell'utente 
            //ex. 10 - 30 => [firstOption, secondOption]
            //ex. 45 - 30 => [secondOption, firstOption]
            return Math.abs(firstAmountDiff) - Math.abs(secondAmountDiff)
        } else  {
            //ritorno per primo l'elemento che ha amount maggiore
            //ex. 10 > -30 
            return firstAmountDiff > secondAmountDiff? -1 : 1
        }
    }
    return firstOption.amount - secondOption.amount
}

const mapExchangeOption = (balance: BalanceAssetInterface[], exchangeApi: ExchangeApi[], assets: AssetInterface[]): (ExchangeOption & {balance?: BalanceAssetInterface})[] => exchangeApi
.flatMap(ex => mapFromExchangeApi(ex, balance , assets))
.sort(sortExchanges)

const actionGetExchangeList = (
    body: any, 
    store: ActionContext<MarketModule, RootStore>
): Promise<(ExchangeOption & {balance?: BalanceAssetInterface | undefined})[]> => {
    return http<ApiResponse<ExchangeApi[]>>({method: 'post', url:`/market/changes`, data: body})
    .then(result => {
        store.commit("setDisplayLoadMore", result.data.length >= store.state.itemPerPage)
        if (result.data){
            const exchangeOptions = mapExchangeOption(store.rootGetters.balance, result.data, store.state.assets)
            return Promise.resolve(exchangeOptions);
        }
        return Promise.resolve([])
    })
    .catch(err => Promise.reject(err))
}

const marketInitState = {
    assets: [],
    exchangeOptions: [],
    marketFilters: {
        asset: { id: "", name: ""},
        assetType: { id: "", name: ""},
        exchangesClosed: false,
        myExchanges: false,
    },
    displayLoadMore: false,
    itemPerPage: 100
}

export const marketplace: Module<MarketModule, RootStore> = {
    namespaced: true,
    state: {
        ...marketInitState
    },
    getters:{
        assets: (state) => state.assets.filter(asset => asset.changeable),
        exchangeOptions: (state, getters, rootState) => {
            return state.exchangeOptions.filter(option => 
                (state.marketFilters.asset && state.marketFilters.asset.id != ""? option.exchange.asset === state.marketFilters.asset.id : true) && 
                (state.marketFilters.assetType && state.marketFilters.assetType.id != ""? option.asset === state.marketFilters.assetType.id : true) && 
                (state.marketFilters.myExchanges? option.exchange.seller === rootState.account.accountId : option.exchange.seller != rootState.account.accountId) &&
                (state.marketFilters.exchangesClosed? option.exchange.status === 'CLOSED' : option.exchange.status === 'OPEN')
            )
        },
        marketplaceFilters: (state) => state.marketFilters,
        balancesFromBc: (state, getters, rootState) => rootState.balance.map(b => {
            const convertedAmount = convertvalueFromBc(b.amount, b.id)
            return {
                ...b,
                amount: convertedAmount? Number(convertedAmount.replace(".", "").replace(",", ".")) : 0
            }
        }),
        displayLoadMore: (state) => state.displayLoadMore,
        buyWithAssetFilterOptions: (state): Pick<AssetInterface, 'id' | 'name'>[] => state.exchangeOptions
            .filter((value, index, result) => result.findIndex(res => res.asset === value.asset) === index)
            .map(option => {
                return {
                    id: option.asset,
                    name: option.assetName,
                }
            })
    },
    mutations: {
        resetStore: (state) => {
            Object.assign(state, marketInitState)
        },
        setFilterMarketplace: (state: MarketModule, payload?: FilterExchangesPayload) => {
            state.marketFilters = payload? {...state.marketFilters, [payload.key]: payload.value} : { ...state.marketFilters, asset: { id: "", name: ""}, assetType: { id: "", name: ""}, myExchanges: false, exchangesClosed: false }
        },
        buyAssetSuccess: (state: MarketModule, payload: ExchangeOption) => {
            //TODO: impplement buy success
        },
        setAssets: (state, payload: AssetInterface[]) => state.assets = payload, 
        setExchangeOptionList: (state, payload: ExchangeOption[]) => {
            state.exchangeOptions = payload
        },
        setDisplayLoadMore: (state, payload: boolean) => state.displayLoadMore = payload 
    },
    actions: {
        getExchangeAssetsList: (store, payload: {emitter: Emitter<Record<EventType, unknown>>}) => {
            http<ApiResponse<MarketplaceResposeData>>({method: 'get', url: `/market/dashboard`})
            .then(result => {
                store.commit("setDisplayLoadMore", result.data.exchanges.length >= store.state.itemPerPage)
                if (result.data){
                    store.commit("setAssets", result.data.assets)
                    const exchangeOptions = mapExchangeOption(store.rootGetters.balance, result.data.exchanges, store.state.assets)
                    store.commit("setExchangeOptionList", exchangeOptions)
                }
            })
            .catch(() => {
                store.commit("ui/setAlertMessage", {
					title: t('Error on load data. Please, try later'),
					type: "error"
				} as AlertMessage, {root: true})
            })
        },
        getSingleAssetExchangeList: (store, payload: {emitter: Emitter<Record<EventType, unknown>>; assetId: string}) => {
            const body = {
                asset: payload.assetId
            }
            actionGetExchangeList(body, store)
            .then(result => {
                store.commit("setExchangeOptionList", result)
            })
            .catch(() => {
                store.commit("ui/setAlertMessage", {
					title: t('Error on load data. Please, try later'),
					type: "error"
				} as AlertMessage, {root: true})
            })
        },
        loadMore: (store, payload: {emitter: Emitter<Record<EventType, unknown>>; assetId: string}) => {
            const body = {
                asset: payload.assetId? payload.assetId : "",
                skip: store.state.exchangeOptions.length
            }
            actionGetExchangeList(body, store)
            .then(result => {
                store.commit("appendExchangeOptions", result)
            })
            .catch(() => {
                store.commit("ui/setAlertMessage", {
					title: t('Error on load data. Please, try later'),
					type: "error"
				} as AlertMessage, {root: true})
            })
        }
    }
}
