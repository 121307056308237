import store from "@/store";
import {
  ApiResponse,
  AssetInterface,
  TransactionListItemDecodedInterface,
  TransactionListItemDecodedUI,
  TransactionListItemInterface,
  User,
} from "../models/types";
import moment from "moment";
import { Account, binConversions, ECDSAKeyPair } from "@affidaty/t2-lib";
import { AlertMessage } from "@/store/modules/uiModule";
import { useNumberFormat } from "@marchintosh94/number-format";
import { Fee, FeeUI, FeeUIMapped } from "@/models/fees-types";
import { Profile } from "@/models/user-types";
import Tx from "./transaction";
import { http } from "@/interceptor/http.interceptor";
import Log from "./log";
import router from "@/router";
import _axios from "axios";
import Cookies from "js-cookie";

/**
 * Set 4 digit after a decimal point
 */
export function formatWithDecimal(value: number) {
  try {
    return useNumberFormat(value).format();
  } catch (err) {
    return "";
  }
}

export const formatNumberWithCustomDecimal = (
  value: number,
  numberOfDecimal = 2
) => {
  try {
    return useNumberFormat(value).format(numberOfDecimal);
  } catch (err) {
    return "";
  }
};

/**
 * Amount conversion between frontend and blockchain
 */
export function convertvalueFromBc(value: number, assetid: string) {
  const assetList = store.getters.availableAssets;
  const asset = assetList.find((item: AssetInterface) => item.id === assetid);
  if (asset)
    return formatNumberWithCustomDecimal(
      Number(value / Math.pow(10, asset.decimal)),
      asset.decimal
    );
}

export function convertvalueToBc(value: number, assetid: string) {
  const assetList = store.getters.availableAssets;
  const asset = assetList.find((item: AssetInterface) => item.id === assetid);
  if (asset) return Number((value * Math.pow(10, asset.decimal)).toFixed(0));
}
export const convertvalueToBcDecimal = (value: number, decimal: number) =>
  Number((value * Math.pow(10, decimal || 0)).toFixed(0));
export const convertvalueFromBcDecimal = (value: number, decimal: number) =>
  Number(value / Math.pow(10, decimal || 0));

export const clearStorage = () => {
  localStorage.removeItem("userInformations");
  localStorage.removeItem("account");
  localStorage.removeItem("iban");
  localStorage.removeItem("walletAddress");
  localStorage.removeItem("orderConfirm");
};

export const changeChatBotLanguage = (locale: string) => {
  if ((window as any).smartsupp && locale) {
    (window as any).smartsupp("language", locale.substring(0, 2));
  }
};

// Format date
export const formatDate = (value: number, seconds = false) => {
  return seconds
    ? moment(value * 1000).format("DD/MM/YYYY hh:mm")
    : moment(value * 1000).format("DD/MM/YYYY");
};

export const getMomentDateFromInt = (value: number) => {
  return moment(value * 1000);
};

//#region UI methods
export const checkOverflow = (el: HTMLElement): boolean => {
  const curOverflow = el.style.overflow;

  if (!curOverflow || curOverflow === "visible") el.style.overflow = "hidden";

  const isOverflowing = el.clientWidth < el.scrollWidth;

  el.style.overflow = curOverflow;

  return isOverflowing;
};

/**
 * Dynamically resize the font of an elemnt if it is overflowing
 * @param el keyup input event
 * @param initialSize the initial font size of the element in PX
 * @param resizeValue the amount to resize the html elemnt in PX
 */
export const resizeInputFontOnOverflow = (
  event: KeyboardEvent,
  initialSize: number,
  resizeValue = 8
): void => {
  if (event.target instanceof HTMLElement) {
    const style = window
      .getComputedStyle(event.target, null)
      .getPropertyValue("font-size");
    const fontSize = parseFloat(style);
    const newsize = fontSize + resizeValue;

    if (
      ["backspace", "delete"].includes(event.key.toLowerCase()) &&
      newsize < initialSize
    ) {
      event.target.style.fontSize = fontSize + resizeValue / 2 + "px";
    }
    if (checkOverflow(event.target)) {
      event.target.style.fontSize = fontSize - resizeValue + "px";
    }

    if (!(event.target as any).value) {
      event.target.style.fontSize = initialSize + "px";
    }
  }
};

export const getStatusClass = (value: string) => {
  switch (value) {
    case "OK":
      return "status-ok";
    case "KO":
      return "status-ko";
    default:
      return "status-pending";
  }
};
export const getProfileStatusClass = (value: string) => {
  switch (value) {
    case "CONFIRMED":
      return "status-ok";
    case "PENDING":
      return "status-pending";
    default:
      return "status-pending";
  }
};
//#endregion

/**
 * Check if object is empty or false (null, undefined...)
 * @param object generic Object
 * @returns boolean
 */
export const isEmptyOrNull = (object: any): boolean => {
  return !object || Object.keys(object).length === 0;
};

// TODO: refactor this method with best practices
export const initUserData = (): Promise<boolean> => {
  return new Promise((resolve) => {
    const userInformations = localStorage.getItem("userInformations");
    if (userInformations !== null) {
      const userInfo: User = JSON.parse(userInformations);
      const account = new Account();
      const keyPair = new ECDSAKeyPair();
      keyPair.publicKey
        .setSPKI(binConversions.base58ToBuffer(userInfo.publicKey))
        .then(() => {
          return account.setKeyPair(keyPair);
        })
        .then(() => {
          return http<ApiResponse<Profile>>({
            method: "get",
            url: `/profile`,
          });
        })
        .then((profile) => {
          store.commit("setUserAccount", account);
          store.commit("setUserInformations", {
            ...userInfo,
            userInfo: {
              ...userInfo.userInfo,
              ...profile.data,
              hasDifferentLivingAddress: !!profile.data.livingAddress,
            },
          });
          store.commit("auth/setPermissions", userInfo.permissions);
          store.commit("setIban", localStorage.getItem("iban") || "");
          store.commit(
            "setWalletAddress",
            localStorage.getItem("walletAddress") || ""
          );
          resolve(true);
        })
        .catch((err) => {
          console.log(err);
          
          localStorage.clear();
          resolve(false);
        });
    } else {
      resolve(false);
    }
  });
};
export const onCopyClipboardWithAlert = (
  value: string,
  alertMessage: string
) => {
  navigator.clipboard.writeText(value).then(() => {
    store.commit("ui/setAlertMessage", {
      title: alertMessage,
      type: "info",
    } as AlertMessage);
  });
};
export const ExchangeConfig = async (): Promise<any> => {
  return http({
    method: "get",
    url: "exchange.config.json",
    httpCallOptions: { baseURL: "/" },
  }).then((res) => {
    return res;
  });
};
export const logoutApplication = () => {
  window.AuthIn4rya.logout()
    .catch(Log.log)
    .finally(() => {
      router.replace("/login").then(() => {
        clearStorage();
        Cookies.remove('AuthSession')
        Cookies.remove('api_tk')
        store.dispatch("resetStore");
      });
    });
};

export const _mapFeesToUI = (fee: Fee) => {
  const assetFeeObject = (
    store.getters.availableAssets as AssetInterface[]
  ).find((asset) => asset.sign == fee.asset);
  return {
    ...fee,
    decimal: assetFeeObject ? assetFeeObject.decimal : 2,
  };
};

export const _groupByFeesByAssetAndMapToUI = (fees: FeeUIMapped[]): FeeUI =>
  fees.reduce((response, fee) => {
    const totalFeeAmount =
      (response[fee.asset]?.totalFeeAmount || 0) + fee.amount;
    if (!isEmptyOrNull(fee)) {
      response[fee.asset] = {
        fee: [...(response[fee.asset]?.fee || []), fee],
        assetSign: fee.asset,
        assetDecimals: fee.decimal,
        totalFeeAmount,
        totalFeeAmountFormatted: useNumberFormat(totalFeeAmount).format(
          fee?.decimal ?? 2
        ),
      };
    }
    return response;
  }, {} as FeeUI);

//TODO: add in frontend helper -> utils library
export const generateFormData = (
  doc: File[],
  extraFormData?: any
): FormData => {
  const formData: FormData = new FormData();
  doc.forEach((elem) => {
    formData.append("Files", elem, elem.name);
  });
  if (extraFormData) {
    for (const key in extraFormData) {
      if (typeof extraFormData[key] == "object") {
        for (const k in extraFormData[key]) {
          if (
            Object.keys(extraFormData[key]).find((key) => key == k) &&
            extraFormData[key][k] instanceof moment
          ) {
            extraFormData[key][k] = moment(extraFormData[key][k]).format(
              "YYYY-MM-DDTHH:MM"
            );
          }
        }
      }
      formData.append(key, JSON.stringify(extraFormData[key]));
    }
  }
  return formData;
};

export const decodeTransaction = async (
  tx: TransactionListItemInterface[]
): Promise<TransactionListItemDecodedInterface[]> => {
  // Se il vettore e` vuoto tx viene reso undefined
  if (tx && tx.length) {
    const list = [];
    for (const t of tx) {
      const obj = await Tx.decodeTransaction(t.transaction);
      list.push({
        id: t.id,
        transaction: obj,
        createdAt: t.createdAt,
        assetId: t.asset,
      });
    }
    return list;
  } else {
    return [];
  }
};
export const mapTransactionWithAsset = (
  tx: TransactionListItemDecodedInterface
): TransactionListItemDecodedUI => {
  return {
    ...tx,
    asset:
      store.state.availableAssets?.find((asset) => asset.id === tx.assetId) ??
      ({} as AssetInterface),
  };
};

// export const handleFileInput = (files: FileList) => {
//     if (files && files.length) {
//         for (let i = 0; i < files.length; i++) {
//         this.documentToUpload.push(files.item(i));
//         let reader: FileReader = new FileReader();
//         reader.readAsDataURL(files[i]);
//         reader.onload = (_event) => {
//             let iconClass = this.utils.getFileIcon(files[i].type);
//             this.fileInputDisplay.push({ reader: reader.result, file: files[i], iconClass: iconClass });
//         }

//         }
//     }
// }
