
import useI18nAffidaty from "@/composable/I18nAffidatyApi";
import { logoutApplication } from "@/utils/common";
import _axios, { AxiosError, AxiosRequestConfig } from "axios";
import { ApiResponseError } from "../models/api-types";
import Log from "../utils/log";
import Cookies from 'js-cookie'
import store from "@/store";

export interface HttpOptions {
    method: "get" | "post" | "delete" | "patch" | "put";
    url: string;
    loader?: boolean;
    data?: any;
    authorization?: boolean;
    httpCallOptions?: AxiosRequestConfig;
}

export const API_TOKEN_COOKIE_KEY = 'api_tk'
const axios = _axios 
axios.defaults.headers.common = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
}

const handleError = (error: AxiosError<ApiResponseError>): AxiosError => {
    const { t } = useI18nAffidaty()
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        Log.log(error.response.data)
        // Log.log(error.response.status)
        // Log.log(error.response.headers)
        const response = error.response?.data
        if(response){
            // trigger alert message error
            switch (error.response.status){
                case 400:
                    break
                case 401:
                    logoutApplication()
                    store.commit('ui/setAlertMessage', {type: 'waring', title: t('Session expired. Please, re-enter credentials')}, {root: true})
                    break
                case 422:
                    break
                case 403:
                    break
                case 500:
                    store.commit('ui/setAlertMessage', {type: 'error', title: response.message}, {root: true})
                    break
                case 504:
                    store.commit('ui/setAlertMessage', {type: 'error', title: 'Service unavailable'}, {root: true})
                    break
            }
        }

    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        Log.log(error.request)
    } else {
        // Something happened in setting up the request that triggered an Error
        Log.log('Error', error.message)
    }
    Log.log(error.config)

    return error
}

export const http = <T>(handler: HttpOptions): Promise<T> => {
    axios.defaults.baseURL = process.env.VUE_APP_EXCHANGE_API_ENDPOINT
    const {
        httpCallOptions, 
        method, 
        url, 
        loader, 
        data, 
        authorization 
    } = handler

    const displayLoader = loader != null && loader != undefined? loader : true
    const bearerAuth = authorization != null && authorization != undefined? authorization : true


    displayLoader? store.commit("ui/showLoader", true, {root: true}) : null
    return axios.request<T>({
        ...httpCallOptions,
        headers: { 
            ...httpCallOptions?.headers,
           'Authorization' : bearerAuth? `bearer ${Cookies.get(API_TOKEN_COOKIE_KEY)}` : undefined
        },
        url,
        method,
        data
    }).then(result => {
        return result.data
    }).catch(error => {
        throw handleError(error)
    })
    .finally(() => {
        displayLoader? store.commit("ui/showLoader", false, {root: true}) : null
    })
}
