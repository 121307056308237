
import { defineComponent, onMounted, ref, toRefs } from "vue"
import type { PropType } from "vue"
import type { FieldList } from "../authIn";

export default defineComponent({
  name: "AuthInComponent",
  props: {
    style: { type: String, required: false, default: 'z-index: 10000; margin: 0px auto 0px auto;' },
    mode: { type: String as PropType<'ghost' | 'normal'>, required: false, default: 'normal'},
    anonymousMessage: { type: String, required: false },
    apiKey: { type: String, required: true },
    application: { type: String, required: false },
    appid: { type: String, required: false },
    buttonClass: { type: String, required: false },
    buttonImageClass: { type: String, required: false },
    buttonSubtitle: { type: String, required: false },
    buttonSubtitleClass: { type: String, required: false },
    buttonTitleClass: { type: String, required: false },
    env: {
      type: String as PropType<"dev" | "prod" | "staging">,
      required: false,
    },
    fields: { type: String, required: false, default: 'name,surname,phone' },
    buttonLabel: { type: String as PropType<FieldList>, required: false },
    lang: { type: String as PropType<'en-US' | 'it-IT'>, required: false },
    writepermission: { type: String, required: false },
    loggedMessage: { type: String, required: false },
    theme: {
      type: String as PropType<"custom" | "default" | "outline" | "simple">,
      required: false,
    },
    unauthorizedMessage: { type: String, required: false },
    onAuthInSuccess: {
      type: Function as PropType<() => void>,
    },
  },
  emits: ["onAuthInReady"],
  setup(prop, { emit }) {
    const authIn = ref(null);
    const { ...data } = toRefs(prop);

    onMounted(() => {
      (authIn.value as any).addEventListener(
        "affidaty:auth-in:ready",
        (event: any) => {
          //console.log("[DEBUG] internal ready")
          emit("onAuthInReady", event.target);
          if (authIn.value as any) {
            (authIn.value as any).authSuccess = data.onAuthInSuccess.value;
            (authIn.value as any).addEventListener(
              "affidaty:auth-in:success",
              (data: any) => {
                const authSuccess = (
                  document.querySelector("affidaty-sign") as any
                ).authSuccess;
                //console.log("[DEBUG] callback instant", authSuccess)
                if (data.detail.event === "logged" && authSuccess) {
                  //console.log("[DEBUG] internal success emit");
                  authSuccess();
                }
              }
            );
          }
        }
      );
    });

    return {
      authIn,
      prop
    };
  },
});
