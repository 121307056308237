import { Permission } from "@/models/types"
import { Certificate } from "@affidaty/t2-lib"
import { invalid } from "moment"
import { ref, shallowRef } from "vue"


interface CertificateAssociation {
    certificate: Certificate;
    base58Cert: string;
}

const corrupted = ref<boolean>(false)
const hasCertificates = ref<boolean>(true)
export default function useAuth(userRoles: Permission[]){
    const roles = shallowRef<Set<string>>(new Set<string>([]))
    const permissions = shallowRef(userRoles || [])
    const certificatesString = shallowRef<string[]>(
        permissions.value.flatMap(permission => {
            return Object.keys(permission).map(key => permission[key].certificate)
        }) || []
    )
    
    const _init = () => {
        hasCertificates.value = certificatesString.value.length > 0
        const mappedRoles: string[] = []
        const mappedPermission: string[] = []
        const mappedRolesPermission: string[] = []
        permissions.value.forEach(role => {
            Object.keys(role).forEach(key => {
                mappedRolesPermission.push( ...role[key].capabilities.map(permission => `${key}.${permission}`) )
                mappedPermission.push( ...role[key].capabilities.map(permission => `${permission}`) )
                mappedRoles.push(key)
            })
        })
        roles.value = new Set([...mappedRoles, ...mappedPermission, ...mappedRolesPermission])
    }

    const _verifyRoleByCertificate = () => {
        let invalidcert = 0
        if (certificatesString.value){
            const permissionPromises = permissions.value.flatMap(permission => {
                return Object.keys(permission).flatMap(key => {
                    const certificate = new Certificate()
                    return certificate.fromBase58(permission[key].certificate).then(() =>{
                        return certificate.verify({[key]: Object.values(permission[key].capabilities).join(";")})
                    }).then(isValid => {
                        !isValid? invalidcert++ : null
                        return Promise.resolve()
                    }).catch(err => {
                        //console.error(err)
                        invalidcert++
                        return Promise.resolve()
                    })
                })        
            })
            return Promise.all(permissionPromises).then(() => {
                corrupted.value = invalidcert > 0
            })
        }  
        return Promise.resolve() 
    }

    const _isAuthorized = (permissions: string[]): boolean => {
        return permissions.filter(permission => roles.value.has(permission)).length > 0
    }

    /**
     * Check if user has permissions
     * The permission object is based on @interface Role
     * @param permissionRequired @type string
     * @param roleRequired @type string | undefined
     * @returns Promise<boolean>
     */
    const isAuthorized = (permissionRequired: string, roleRequired?: string): boolean => {
        const fullPermissionRequired = `${roleRequired? `${roleRequired}.`: ""}${permissionRequired}`

        return _isAuthorized([fullPermissionRequired])
    }
    /**
     * Check if user has the role specified
     * @param roleRequired string | string[]
     * @returns Promise<boolean>
     */
    const isAuthorizedRole = (roleRequired: string | string[]): boolean => {
        const fullPermissionRequired: string[] = roleRequired instanceof Array? roleRequired : [roleRequired]
        
        return _isAuthorized(fullPermissionRequired)
    }

    _init()
    return {
        corrupted,
        hasCertificates,
        hasPermissions: roles.value.size > 0,
        isAuthorized,
        isAuthorizedRole,
        verifyPermissionsIntegrity: _verifyRoleByCertificate
    }
}