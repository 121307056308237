import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw, useRouter } from 'vue-router';
import store from '@/store';
import useI18nAffidaty from '@/composable/I18nAffidatyApi';
import { changeChatBotLanguage, clearStorage, initUserData, isEmptyOrNull } from '@/utils/common';
import useAuth from '@/composable/AuthApi';
import { Permission } from '@/models/types';
import { getAssetBalance, getLang, goExchange, updateProfile } from '@/utils/iframe27.utils';

const routes: Array<RouteRecordRaw> = [
   {
    path: '/',
    name: 'init',
    redirect: '/ext/evocoin',
  },
/*  {
    path: '/start',
    name: 'Start',
    component: () => import('@/components/Start.vue'),
  },
  {
    path: '/corrupted',
    name: 'Corrupted',
    component: () => import('@/views/CorruptedCertificates.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '/r/:accountId',
    name: 'checkpointReferral',
    component: () => import('@/views/RedirectReferral.vue'),
  },
  {
    path: '/dashboard/:status?/:txid?',
    name: 'Dashboard',
    component: () => import('@/views/Dashboard.vue'),
    meta: {
      permissions: ['user', 'merchant', 'admin'],
      title: 'Dashboard'
    }
  },
  {
    path: '/get-referral',
    name: 'getReferral',
    component: () => import('@/views/ReferralView.vue'),
    meta: {
      permissions: ['user', 'merchant', 'admin'],
      title: 'Invite a friend'
    }
  },
  {
    path: '/referral-details/:referralAccountId',
    name: 'referralDetails',
    component: () => import('@/views/ReferralDetails.vue'),
    meta: {
      permissions: ['user', 'merchant', 'admin'],
      title: 'Referral Details'
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('@/views/Profile.vue'),
    meta: {
      permissions: ['user', 'merchant', 'admin'],
      title: 'Profile'
    }
  },
  {
    path: '/transactions',
    name: 'Transaction',
    component: () => import('@/views/Transactions.vue'),
    meta: {
      permissions: ['user', 'merchant', 'admin'],
      title: 'Transaction'
    }
  },
  {
    path: '/token-detail/:id',
    name: 'TokenDetail',
    component: () => import('@/views/TokenDetail.vue'),
    meta: {
      permissions: ['user', 'merchant', 'admin'],
      title: 'Token Detail'
    }
  },
  {
    path: '/crypto-detail/:id',
    name: 'CryptoDetail',
    component: () => import('@/views/CryptoDetail.vue'),
    meta: {
      title: 'Crypto Detail',
      permissions: ['user', 'merchant', 'admin'],
    }
  },
  // {
  //   path: '/marketplace',
  //   name: 'Marketplace',
  //   component: () => import('@/views/Marketplace.vue'),
  //   meta: {
  //     permissions: ['user', 'merchant', 'admin'],
  //     title: 'Marketplace'
  //   }
  // },
  {
    path: '/nft',
    name: 'Nft',
    props: {
      callbacksMap: new Map<string, Function>([
        ["updateProfile", updateProfile],
        ["goExchange", goExchange],
      ]),
      iframeSrc: "/assets/external/bridge/"

    },
    component: () => import('@/views/ZeroTwoSevenView.vue'),
    meta: {
      permissions: ['user', 'merchant', 'admin'],
      title: 'Nft'
    }
  },
  {
    path: '/asset/:assetId',
    name: 'assetDetail',
    props: {
      callbacksMap: new Map<string, Function>([
        ["goExchange", goExchange],
      ]),
      iframeSrc: "/assets/external/bridge/"
    },
    component: () => import('@/views/ZeroTwoSevenView.vue'),
    meta: {
      permissions: ['user', 'merchant', 'admin'],
      title: 'Asset detail'
    }
  },
  // {
  //   path: '/redeem/:assetId',
  //   name: 'redeemasset',
  //   props: {
  //     callbacksMap: new Map<string, Function>([]),
  //     iframeSrc: "/assets/external/bridge/",
  //     payloadData: {
  //       balance: getAssetBalance(),
  //       lang: getLang()
  //     }
  //   },
  //   component: () => import('@/views/ZeroTwoSevenView.vue'),
  //   meta: {
  //     permissions: ['user', 'merchant', 'admin'],
  //     title: 'Redeem'
  //   }
  // },
  {
    path: '/thankyou',
    name: 'ThankYou',
    component: () => import('@/components/common/ThankYouPage.vue'),
    meta: {
      permissions: ['user', 'merchant', 'admin'],
      title: 'Grazieee'
    }
  }, */
  {
    path: '/evothankyou',
    name: 'EvoThanks',
    component: () => import('@/components/common/EvoThanks.vue'),
    meta: {
      permissions: ['user', 'merchant', 'admin'],
      title: 'Grazieee'
    }
  },
  {
    path: '/ext/:coin',
    name: 'Ext',
    component: () => import('@/views/Ext.vue'),
  },
/*   {
    path: '/admin',
    name: 'Admin',
    component: () => import('@/views/admin/AdminLanding.vue'),
    redirect: '/admin/dashboard',
    meta: {
      permissions: ['merchant', 'admin'],
    },
    children: [
      {
        path: 'dashboard',
        name: 'AdminDashboard',
        component: () => import('@/views/admin/AdminDashboard.vue'),
        meta: {
          permissions: ['merchant', 'admin'],
          title: 'Admin Dashboard'
        }
      },
      {
        path: 'escrow',
        name: 'Escrow',
        component: () => import('@/views/admin/AdminEscrow.vue'),
        meta: {
          permissions: ['merchant', 'admin.escrow:list'],
          title: 'Escrow'
        }
      },
      {
        path: 'withdraw',
        name: 'Withdraw',
        component: () => import('@/views/admin/AdminWithdraw.vue'),
        meta: {
          permissions: ['merchant', 'admin.withdraw:list'],
          title: 'Withdraw'
        }
      },
      {
        path: 'profiling',
        name: 'Profiling',
        component: () => import('@/views/admin/AdminProfiling.vue'),
        meta: {
          permissions: ['admin.profile:view'],
          title: 'Profiling'
        }
      },
      {
        path: 'profiling/:id',
        name: 'UserProfile',
        component: () => import('@/views/admin/AdminUserProfile.vue'),
        meta: {
          permissions: ['admin.profile:view'],
          title: 'User Detail'
        }
      },
      {
        path: 'purchases-list',
        name: 'Purchases',
        component: () => import('@/views/admin/AdminPurchase.vue'),
        meta: {
          permissions: ['admin.purchase:view'],
          title: 'Purchases'
        }
      },
    ]
  } */
]

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  localStorage.removeItem("payment")
  
  //#region i18n
  const {loadLocaleMessages, defaultLocale} = useI18nAffidaty()
  store.dispatch("checkLanguage")
  const lang = store.state.language? store.state.language : defaultLocale
  if (lang){
    const i18nUrl = `${process.env.VUE_APP_I18N_BASE_URL}/${lang}.json`
    loadLocaleMessages(lang, i18nUrl).then(lang => changeChatBotLanguage(lang))
  }
  //#endregion
  
  // Gestione ritorno dal payment gateway
  if(to.path.match(new RegExp(/\/dashboard\/(success|error)\/([a-zA-z0-9])+/))) {
    const explodedMatch = to.path.split('/');
    const payment: { status: string; txid: string } = {
      status: explodedMatch[explodedMatch.length - 2],
      txid: explodedMatch[explodedMatch.length - 1]
    };
    store.commit("setCurrentPayment", payment);
  }
  await initUserData()

  const userRole: Permission[] = store.getters["auth/permissions"]
  const { 
    isAuthorized, 
    corrupted, 
    hasCertificates, 
    hasPermissions,
    verifyPermissionsIntegrity
  } = useAuth(store.getters["auth/permissions"])
  await verifyPermissionsIntegrity()
  if(to.meta && to.meta.permissions) {

    if (!hasCertificates.value || !hasPermissions){
      if(window.AuthIn4rya){
        window.AuthIn4rya.logout().catch()
      } 
      clearStorage()
      store.dispatch("resetStore")
      //check if user navigate in the app from shared link to send token and save that data in storage
      if (to.query.accountTo){
        localStorage.setItem('promiseOnLogin', document.location.href)
      }
      next({path: "/"})
    }
    if (!userRole.length){
      next({name: 'Start'})
    } else if (corrupted.value) {
      next({name: "Corrupted"})
    } else {
      (to.meta.permissions as string[]).filter(perm => isAuthorized(perm)).length? next() : next({name: 'Start'})
    }
  } else {
    next()
  }
  store.commit('auth/setIsAuthenticated', !isEmptyOrNull(store.state.userInformations))
})

export default router
