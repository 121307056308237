import Swal, { SweetAlertOptions, SweetAlertResult, SweetAlertIcon, SweetAlertCustomClass } from 'sweetalert2'

export default function useSweetAlertApi() {
    const fireAlertMessage = <T = any>(type: SweetAlertIcon, options: SweetAlertOptions): Promise<SweetAlertResult<T>> => {
        return Swal.fire({
            showConfirmButton: false,
            ...options,
            toast: true,
            icon: type,
            timerProgressBar: true,
            timer: options.timer? options.timer : 3000,
            customClass: {
                ...options.customClass as SweetAlertCustomClass,
                popup: 'exxe__swal2-toast'
            },
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })
    }

    const fireConfirmMessage = <T = any>(type: SweetAlertIcon | '', options: SweetAlertOptions): Promise<SweetAlertResult<T>> => {
        const defaultSwalOption: Pick<SweetAlertOptions, 'customClass' | 'icon'> | {} = type !== '' ? 
            { 
                icon: type as SweetAlertIcon,
                customClass: {
                    ...options.customClass as SweetAlertCustomClass,
                    cancelButton: "exxe__swal2-cancel-button",
                    confirmButton: "exxe__swal2-confirm-button",
                    denyButton: "exxe__swal2-deny-button"
                }
            } : {} 
        return Swal.fire({
            showCancelButton: true,
            toast: false,
            target: "#app",
            ...defaultSwalOption,
            ...options,
        })
    }

    return {
        fireAlertMessage,
        fireConfirmMessage
    }
}