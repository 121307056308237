import { ApiResponse, AssetInterface, BalanceApiResponse, BalanceAssetInterface, OperationConfirmProps, TransactionListItemDecodedInterface, TransactionListItemInterface, TransactionUI, User } from '@/models/types';
import { convertvalueFromBc, convertvalueFromBcDecimal, decodeTransaction, mapTransactionWithAsset } from '@/utils/common';
import { Account } from '@affidaty/t2-lib';
import { createLogger, createStore } from 'vuex';
import { marketplace } from './modules/marketplace';
import { ui } from './modules/uiModule';
import { administration } from './modules/administration';
import { auth } from './modules/auth';
import { externalCrypto } from './modules/externalCrypto';
import { useNumberFormat } from '@marchintosh94/number-format';
import { http } from '@/interceptor/http.interceptor';

export interface RootStore {
  account: Partial<Account>;
  privateKey: any;
  userInformations?: User;
  transactions: TransactionUI<TransactionListItemDecodedInterface>;
  balance: BalanceAssetInterface[];
  activeBalance?: BalanceAssetInterface;
  availableAssets: AssetInterface[];
  detailPageToken?: AssetInterface;
  currentPayment: {
      status: string;
      txid: string;
  };
  language: string;
  iban: string;
  walletAddress: string;
  profilePageDefaultActiveTab: number | null;
  operationConfirm: OperationConfirmProps;
  availableLanguages: string[];
  assetConversion: BalanceAssetInterface[];
}

const storeInitState: RootStore = {
  account: {},
  privateKey: null,
  userInformations: undefined,
  transactions: {} as TransactionUI<TransactionListItemDecodedInterface>,
  balance: [],
  activeBalance: undefined,
  availableAssets: [],
  detailPageToken: undefined,
  currentPayment: {
    status: "",
    txid: "",
  },
  language: "",
  iban: "",
  walletAddress: "",
  profilePageDefaultActiveTab: null,
  operationConfirm: {} as OperationConfirmProps,
  availableLanguages: ['it-IT', 'en-US'],
  assetConversion: []
}

const modules = {
  marketplace,
  ui,
  administration,
  auth,
  externalCrypto
}

export default createStore<RootStore>({
  state: {
    ...storeInitState
  },
  
  getters: {
    account: state => state.account,
    userInformations: ((state) => state.userInformations),
    userName: state => `${state.userInformations?.userInfo?.name || 'User'} ${state.userInformations?.userInfo?.surname || ''}`,
    transactions: state => {
      return {
        incoming: state.transactions?.incoming?.map(mapTransactionWithAsset),
        outgoing: state.transactions?.outgoing?.map(mapTransactionWithAsset)
      }
    },
    currencyBalance: state =>  useNumberFormat(
        (state.assetConversion?.map(bal => convertvalueFromBcDecimal(bal.amount, bal.decimal) * bal.conversionRate).reduce((res, val) => res + val, 0) || 0 )
    ).formatCurrency(),
    balance: state => state.balance,
    activeBalance: ((state) => state.activeBalance),
    availableAssets: state => state.availableAssets.filter(item => item.listable),
    buyableAssets: state => state.availableAssets.filter(item => item.buyable),
    changeableAssets: state => state.availableAssets.filter(item => item.changeable && state.balance.find(bal => bal.id === item.id)),
    withdrawableAssets: state => state.availableAssets.filter(item => item.withdrawable && state.balance.find(bal => bal.id === item.id)),
    sendReceiveAssets: state => state.availableAssets.filter(item => ["BTC", "EEUR", "EURS"].includes(item.sign)),
    detailToken: ((state: any) => state.detailPageToken),
    language: ((state: any) => state.language),
    iban: (state) => state.iban,
    walletAddress: (state) => state.walletAddress,
    operationConfirm: (state) => state.operationConfirm
  },

  actions: {
    setUserAccount({commit}, payload) {
      commit('setUserAccount', payload);
    },

    setUserInformations({commit}, payload) {
      commit('setUserInformations', payload);
    },

    setTransactionList({commit}, payload) {
      commit('setTransactionList', payload)
    },

    getTransactionList({commit}) {
      http<ApiResponse<{transactions: TransactionUI<TransactionListItemInterface>}>>({
        method: 'post', 
        url: `/account/transactions`
      }).then(async transactionResponse => {
        commit('setTransactionList', {
          incoming: await decodeTransaction(transactionResponse.data.transactions.incoming),
          outgoing: await decodeTransaction(transactionResponse.data.transactions.outgoing)
        });
      }).catch(error => {
        console.log(error)
      })
    },

    getBalance({commit, state}) {
       return http<ApiResponse<BalanceApiResponse>>({method: 'post', url: `/account/balance`, httpCallOptions: {headers: {'cache-control': 'no-cache'}}}).then(balanceResponse => {
        const bal = balanceResponse.data.balance.map(bal => {
          return {
            ...bal,
            conversionRate: balanceResponse.data?.availableAssets?.find(asset => asset.id === bal.id)?.conversionRate ?? 0
          }
        })
        commit('setBalance', bal);
        commit('setAssetConversion', bal)
        if (!state.activeBalance && balanceResponse.data.balance && balanceResponse.data.balance.length) {
          const activeBalance = balanceResponse.data.balance[0] as BalanceAssetInterface
          commit("setActiveBalance", {...activeBalance, amount: convertvalueFromBc(activeBalance.amount, activeBalance.id)})
        }
      }).catch(error => {
        console.log(error)
      })
    },

    getAvailableAssets({commit, state}) {
      http<ApiResponse<{availableAssets: AssetInterface[]}>>({method: 'get', url: `/exchange/available-assets`})
      .then(response => {
        commit('setAvailableAssets', response.data.availableAssets)
      }).catch(error => {
        console.log(error)
      })
    },

    setDetailPageToken({commit}, payload) {
      commit('setDetailPageToken', payload);
    },

    checkLanguage({commit, state}){
      let lang = localStorage.getItem("language")
      if (!lang){
        lang = state.availableLanguages.indexOf(navigator.language) >= 0? navigator.language : 'en-US'
      } 
      if (lang != state.language && lang != null){
        commit("setLanguage", lang)
      }
    },

    resetStore: ({commit}) => {
      commit("resetStore")
      Object.keys(modules).forEach(m => {
        commit(`${m}/resetStore`)
      })
    },

    setLanguage({commit}, payload: string){
      commit("setLanguage", payload)
    }
  },

  mutations: {
    setUserAccount(state: any, payload) {
      state.account = payload;
    },

    setPrivateKey(state: any, payload) {
      state.privateKey = payload;
    },
    
    setUserInformations(state: any, payload) {
      state.userInformations = payload;
    },

    setTransactionList(state: any, payload) {
      state.transactions = payload;
    },

    setBalance(state: any, payload) {
      state.balance = payload;
    },

    setActiveBalance(state, payload: BalanceAssetInterface){
      state.activeBalance = payload
    },

    setAvailableAssets(state: any, payload) {
      state.availableAssets = payload;
    },

    setDetailPageToken(state: any, payload) {
      const assetToken = state.availableAssets.find((t: any) => t.id === payload);
      state.detailPageToken = assetToken
        
    },

    setLanguage(state: any, payload: string){
      localStorage.setItem("language", payload)
      state.language = payload
    },

    setIban: (state, payload: string) => {
      state.iban = payload
    },

    setWalletAddress: (state, payload: string) => {
      state.walletAddress = payload
    },

    setProfileActiveTab: (state, payload = null) => {
      state.profilePageDefaultActiveTab = payload
    },

    setOperationConfirm: (state, payload: OperationConfirmProps) => {
      state.operationConfirm = payload
    },

    setCurrentPayment: (state, payload: {status: string; txid: string } = {status: "", txid: ""}) => {
      state.currentPayment = payload
    },

    setAssetConversion: (state, payload: BalanceAssetInterface[]) => {
      state.assetConversion = payload
    },

    resetStore: (state) => {
      Object.assign(state, storeInitState)
    }
  },
  modules: {
    ...modules
  },
  plugins: !process.env.NODE_ENV //== 'production'
    ? [createLogger()]
    : []
})
