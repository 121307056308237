import { http } from "@/interceptor/http.interceptor";
import { AdminProfile, AdminProfileUI, DocUI, ProfileApiResponse } from "@/models/admin-profiles-types";
import { PurchaseApiResponse, PurchaseUI } from "@/models/admin-purchase-types";
import { Escrow, EscrowApiResponse } from "@/models/escrow-types";
import {
  AdminPagesFilters,
  ApiResponse,
  EscrowStatus,
  FilterCallParams,
  TransactionAccountData
} from "@/models/types";
import {
  Withdraw,
  WithdrawApiResponse,
} from "@/models/withdraw-types";
import {
  formatDate,
  getMomentDateFromInt,
  _groupByFeesByAssetAndMapToUI
} from "@/utils/common";
import { getFileExtensionFromBase64 } from "@/utils/file-utility";
import Log from "@/utils/log";
import Tx from "@/utils/transaction";
import { ECDSAKey } from "@affidaty/t2-lib";
import { useNumberFormat } from "@marchintosh94/number-format";
import { Module } from "vuex";
import { RootStore } from "..";

export interface AdminModule {
  escrow: Escrow[];
  withdraw: Withdraw[];
  purchases: PurchaseUI[];
  profiles: AdminProfileUI[];
  escrowStatusOptions: { key: EscrowStatus; label: string }[];
  profileStatusOptions: string[];
  escrowCount: number;
  withdrawCount: number;
  purchasesCount: number;
  profilesCount: number;
  profile: AdminProfileUI;
}

const adminInitState: AdminModule = {
  escrow: [],
  withdraw: [],
  purchases: [],
  profiles: [],
  escrowCount: 0,
  withdrawCount: 0,
  purchasesCount: 0,
  profilesCount: 0,
  escrowStatusOptions: [
    { key: "CLOSED_KO", label: "Ko" },
    { key: "CLOSED_OK", label: "Ok" },
    { key: "OPEN", label: "Pending" },
  ],
  profileStatusOptions: [],
  profile: {} as AdminProfileUI

}

const mapProfileData = (profile: AdminProfile): AdminProfileUI => {
  let type
  switch (profile.profileType) {
    case 'business':
      type = 'Business'
      break
    case 'legalPerson':
      type = 'Legal Person'
      break
    case 'naturalPerson':
      type = 'Natural Person'
      break
    default:
      type = ''
      break;
  }
  const documents: DocUI[] = []
  if(profile.profileType != 'business' && profile.docs?.userDoc){
    documents.push(
      ...profile.docs.userDoc.map(doc => { 
        return {
          label: `Identity Document ${doc.name.indexOf('front') >= 0? 'Front' : 'Back'}`, 
          file: doc.file, 
          name: `${doc.name.replaceAll(' ', '_')}_${profile?.name}_${profile?.surname}.${getFileExtensionFromBase64(doc.file)}`
        }
      })
    )
  }
  if(profile.profileType == 'legalPerson' && profile.docs?.vatCertificate){
    documents.push(
      {
        label: 'Certificate of VAT number attribution', 
        file: profile.docs.vatCertificate.file, 
        name: `${profile.docs.vatCertificate.name.replaceAll(' ', '_')}.${getFileExtensionFromBase64(profile.docs.vatCertificate.file)}` 
      }
    )
  }

  if(profile.profileType == 'business' && profile.docs?.companyLegalDoc){
    documents.push(
      ...profile.docs.companyLegalDoc.map(doc => { 
        return {
          label: `Legal Representative ID ${doc.name.indexOf('front') >= 0? 'Front' : 'Back'}`, 
          file: doc.file, 
          name: `${doc.name.replaceAll(' ', '_')}_${profile?.name}_${profile?.surname}.${getFileExtensionFromBase64(doc.file)}`
        }
      }),
      {
        label: '',
        file: profile.docs.companyVisurDoc.file,
        name: `${profile.docs.companyVisurDoc.name.replaceAll(' ', '_')}.${getFileExtensionFromBase64(profile.docs.companyVisurDoc.file)}` 
      }
    )
  }
  return {
    ...profile,
    profileTypeUI: type,
    documentsUI: documents,
    hasDifferentLivingAddress: !!profile.livingAddress
  } 
}

const mapStatusUi = (status: string) => {
  switch (status) {
    case "OPEN":
      return 'PENDING'
    case "CLOSED_OK":
      return 'OK'
    case "CLOSED_KO":
      return 'KO'
    default:
     return 'N/D'
  }
}


const prepareUpdateTx = (profile: AdminProfileUI, publicKey: ECDSAKey) =>  window.AuthIn4rya.getUserAccountId().then(accountId => {
  const transactionAccountData: TransactionAccountData = {
    accountId,
    publicKey
  }
  const { id, ...data} = profile
  const args = {
    accountId: id
  }
  return Tx.createTx(transactionAccountData, 'confirm_profile', args)
}).then(unsignedTx => {
  return Tx.signTx(unsignedTx)
})

export const administration: Module<AdminModule, RootStore> = {
  namespaced: true,
  state: {
    ...adminInitState,
  },
  getters: {
    escrow: state => state.escrow,
    escrowCount: state => state.escrowCount,
    withdraw: state => state.withdraw,
    withdrawCount: state => state.withdrawCount,
    purchases: state => state.purchases,
    purchasesCount: state => state.purchasesCount,
    profiles: state => state.profiles,
    profilesCount: state => state.profilesCount,
    escrowStatusOptions: state => state.escrowStatusOptions,
    profile: state => state.profile
  },
  mutations: {
    resetStore: (state) => {
      Object.assign(state, adminInitState);
    },
    getEscrowListSuccess: (state, payload: Escrow[]) => {
      state.escrow = payload;
    },
    getWithdrawListSuccess: (state, payload: Withdraw[]) => {
      state.withdraw = payload;
    },
    getPurchaseListSuccess: (state, payload: PurchaseUI[]) => {
      state.purchases = payload;
    },
    getProfileListSuccess: (state, payload: AdminProfileUI[]) => {
      state.profiles = payload;
    },
    setProfileCount: (state, payload: number) => {
      state.profilesCount = payload;
    },
    setPurchaseCount: (state, payload: number) => {
      state.purchasesCount = payload;
    },
    setWithdrawCount: (state, payload: number) => {
      state.withdrawCount = payload;
    },
    setEscrowCount: (state, payload: number) => {
      state.escrowCount = payload;
    },
    setEscrowStatusOptions: (state, payload: EscrowStatus[]) => {
      state.escrowStatusOptions = payload.map((item) => {
        return {
          key: item,
          label:
            item === "OPEN" ? "Pending" : item === "CLOSED_KO" ? "KO" : "OK",
        };
      });
    },
    setProfileStatusOptions: (state, payload: string[]) => {
      state.profileStatusOptions = payload
    },
    updateProfile: (state, data: AdminProfile) => {
      state.profiles = [...state.profiles.filter(profile => profile.id != data.id), mapProfileData(data)]
    },
    setSingleProfile: (state, data: AdminProfile) => {
      state.profile = mapProfileData(data)
    }
  },
  actions: {
    getEscrowList: (store, payload: FilterCallParams<AdminPagesFilters>) => {
      return http<ApiResponse<EscrowApiResponse>>({
        method: "post",
        url: `/admin/fastPay/escrow`,
        data: payload,
      }).then((response) => {
        const escrows: Escrow[] = response.data.escrow.map((item) => {
          return {
            ...item,
            // amount: `${item.amount} ${item.asset.sign}`, Need field from Back-End and type on Escrow-types
            paymentId: `...${item.paymentId?.slice(-8)}`,
            assetName: item.asset.name,
            merchantName: item.merchant.name,
            shortCustomer: `${item.customer.slice(
              0,
              4
            )}...${item.customer.slice(-4)}`,
            createdAtMoment: getMomentDateFromInt(item.createdAt),
            createdAtFormat: getMomentDateFromInt(item.createdAt).format(
              "DD/MM/YYYY"
            ),
            statusUI: mapStatusUi(item.status)
          };
        });
        store.commit("getEscrowListSuccess", escrows)
        store.commit('setEscrowCount', response.data.docCount)
        store.commit("setEscrowStatusOptions", response.data.status)
      })
    },
    getWithdrawList: (
      { commit },
      payload: FilterCallParams<AdminPagesFilters>
    ) => {
      return http<ApiResponse<WithdrawApiResponse>>({
        method: "post",
        url: `/admin/withdraw`,
        data: payload,
      }).then((response) => {
        const withdraws: Withdraw[] = response.data.withdraw.map((item) => {
          return {
            ...item,
            shortId: `${item.id.slice(0, 4)}...${item.id.slice(-4)}`,
            assetName: item.asset.name,
            amountFormatted: useNumberFormat(
              item.appliedFee.total.value
            ).formatCurrency(
              item.appliedFee.total.decimal,
              item.appliedFee.total.sign
            ),
            totalFee: Object.values(
              _groupByFeesByAssetAndMapToUI(item.appliedFee.fee)
            )
              .map((fee) => `${fee.totalFeeAmountFormatted} ${fee.assetSign}`)
              .join(" - "),
            destination: item.iban ? "Bank transfer" : "Public chain",
            createdAtMoment: getMomentDateFromInt(item.createdAt),
            createdAtFormat: getMomentDateFromInt(item.createdAt).format(
              "DD/MM/YYYY"
            ),
            
            statusUI: mapStatusUi(item.status)
          };
        });
        commit("getWithdrawListSuccess", withdraws);
        commit('setWithdrawCount', response.data.docCount)
      });
    },
    getPurchaseList: ({ commit }, payload: FilterCallParams<AdminPagesFilters>) => {
      return http<ApiResponse<PurchaseApiResponse>>({
        method: "post",
        url: `/admin/buy`,
        data: payload,
      }).then(response => {
        const buyList: PurchaseUI[] = response.data.buy.map((item) => {
            const currencySign = item.currency == 'EUR'? '€' : ''
          return {
            ...item,
            shortId: `${item.id.slice(0, 4)}...${item.id.slice(-4)}`,
            assetName: item.asset.name,
            formattedAmount: useNumberFormat(item.amount).formatCurrency(2, currencySign),
            feeAmountFormatted: useNumberFormat(item.feeAmount).formatCurrency(2, currencySign),
            formattedUnit: useNumberFormat(item.units).formatCurrency(item.asset.decimal, item.asset.name),
            formattedCreatedAt: formatDate(item.createdAt, true),
            statusUI: mapStatusUi(item.status)
          };
        });
        commit("getPurchaseListSuccess", buyList);
        commit('setPurchaseCount', response.data.docCount)
      })
    },
    getProfileList: ({ commit }, payload: FilterCallParams<AdminPagesFilters>) => {
      return http<ApiResponse<ProfileApiResponse>>({
        method: "get",
        url: `/admin/profile`,
        data: payload
      }).then(response => {
        commit("getProfileListSuccess", response.data.profiles.map(mapProfileData))
        commit('setProfileCount', response.data.docCount)
        commit('setProfileStatusOptions', response.data.status)
      })
    },
    confirmEscrow: (_, payload: string) => {
      return http<ApiResponse<any>>({
        method: "post",
        url: `/admin/fastPay/escrow/update`,
        data: {
          tx: payload,
        },
      });
    },
    abortEscrow: (_, payload: string) => {
      return http<ApiResponse<any>>({
        method: "post",
        url: `/admin/fastPay/escrow/update`,
        data: {
          tx: payload,
        },
      });
    },
    confirmWithdraw: (_, payload: string) => {
      return http<ApiResponse<any>>({
        method: "post",
        url: `/admin/withdraw/update`,
        data: {
          tx: payload,
        },
      });
    },
    abortWithdraw: (_, payload: string) => {
      return http<ApiResponse<any>>({
        method: "post",
        url: `/admin/withdraw/update`,
        data: {
          tx: payload,
        },
      });
    },
    updateEscrowNote: (_, payload: { fastPayId: string; note: string }) => {
      return http<ApiResponse<any>>({
        method: "post",
        url: `/admin/fastPay/escrow/add-note`,
        data: payload,
      });
    },
    updateWithdrawNote: (_, payload: { withdrawId: string; note: string }) => {
      return http<ApiResponse<any>>({
        method: "post",
        url: `/admin/withdraw/add-note`,
        data: payload,
      });
    },
    updatePurchaseNote: (_, payload: { buyId: string; note: string }) => {
      return http<ApiResponse<any>>({
        method: "post",
        url: `/admin/buy/add-note`,
        data: payload,
      });
    },
    confirmUpdateProfile: ({commit, rootState}, profile: AdminProfileUI): Promise<ApiResponse<AdminProfile>> => {
      return prepareUpdateTx(profile, rootState.account.keyPair!.publicKey).then(signedTx => {
        return http<ApiResponse<AdminProfile>>({
          method: 'post',
          url: '/admin/profile/confirm',
          data: {tx: signedTx}
        })
      }).then(res => {
        commit('updateProfile', res.data)
        return Promise.resolve(res)
      }).catch(err => {
        Log.error(err)
        return Promise.reject('Error on update')
      })
    },
    abortUpdateProfile: ({commit, rootState}, profile: AdminProfileUI): Promise<ApiResponse<AdminProfile>> => {
      return prepareUpdateTx(profile, rootState.account.keyPair!.publicKey).then(signedTx => {
        return http<ApiResponse<AdminProfile>>({
          method: 'post',
          url: '/admin/profile/abort ',
          data: {tx: signedTx}
        })
      }).then(res => {
        commit('updateProfile', res.data)
        return Promise.resolve(res)
      }).catch(err => {
        Log.error(err)
        return Promise.reject('Error on update')
      })
    },
    downgradeProfile: ({commit, rootState}, profile: AdminProfileUI): Promise<ApiResponse<AdminProfile>> => {
      return prepareUpdateTx(profile, rootState.account.keyPair!.publicKey).then(signedTx => {
        return http<ApiResponse<AdminProfile>>({
          method: 'post',
          url: '/admin/profile/downgrade',
          data: {tx: signedTx}
        })
      }).then(res => {
        commit('updateProfile', res.data)
        return Promise.resolve(res)
      }).catch(err => {
        Log.error(err)
        return Promise.reject('Error on update')
      })
    },
    getProfile: ({commit}, payload: string) => {
      return http<ApiResponse<AdminProfile>>({
        method: 'get',
        url: `/admin/profile/${payload}`
      }).then(res => {
        console.log(res);
        commit('setSingleProfile', res.data)
      })
    }
  }
}

