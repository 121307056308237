export const getMimeTypeFromBase64 = (data: string): string => {
  switch (data.charAt(0)) {
    case '/':
      return 'image/jpeg'
    case 'R':
      return 'image/gif'
    case 'i':
      return 'image/png'
    case 'J':
      return 'application/pdf'
    case 'U':
      return 'image/webp'
    case 'P':
      return 'image/svg+xml'
    default:
      return ''
  }
}

// export const urltoFile = (url: string, filename: string): Promise<File> =>
//   fetch(url)
//     .then(res => res.arrayBuffer())
//     .then(buf => new File([buf], filename,{type: )}))

export const isImage = (fileType: string): boolean => fileType.match(/image\/*/) != null
export const isPDF = (fileType: string): boolean => fileType.match(/application\/pdf/) != null

export const blobFromDataUrl = (dataUrl: string): Blob => {
  const byteCharacters = atob(dataUrl.split(',')[1]);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], {type: getMimeTypeFromBase64(dataUrl.split(',')[1])} );
}

export const resizeImage = (file: File, maxSize = 720): Promise<string> => {
  return new Promise((resolve) => {
    if(file.type.match(/image.*/)) {
      // Load the image
      const reader = new FileReader();
      reader.onload = () => {
          const image = new Image();
          image.onload = () => {
            // Resize the image
            const canvas = document.createElement('canvas')
            let width = image.width
            let height = image.height
            if (width > height) {
                if (width > maxSize) {
                  height *= maxSize / width
                  width = maxSize
                }
            } else {
                if (height > maxSize) {
                  width *= maxSize / height
                  height = maxSize;
                }
            }
            canvas.width = width
            canvas.height = height
            canvas.getContext('2d')!.drawImage(image, 0, 0, width, height);
            const dataUrl = canvas.toDataURL(file.type);
            return resolve(dataUrl)
          }
          image.src = reader.result as string;
      }
      reader.readAsDataURL(file);
    }
  })
}

export const getFileExtensionFromBase64 = (base64Data: string): string => {
  if(!base64Data){
    return ''
  }
  const [,type] = base64Data.split(';')[0].split('/')
  return type
}