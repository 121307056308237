import { Permission} from "@/models/types";
import { Module } from "vuex";
import { RootStore } from "..";

export interface AuthModule {
    permissions: Permission[];
    isAuthenticated: boolean;
}

const authInitState: AuthModule = {
    permissions: [],
    isAuthenticated: false
}

export const auth: Module<AuthModule, RootStore> = {
    namespaced: true,
    state: {
        ...authInitState
    },
    getters: {
        permissions: state => state.permissions,
        isAuthenticated: state => state.isAuthenticated
    },
    mutations: {
        resetStore: (state) => {
            Object.assign(state, authInitState)
        },
        setPermissions: (state, payload: Permission[]) => {
            state.permissions = payload
        },
        setIsAuthenticated: (state, payload: boolean) => {
            state.isAuthenticated = payload
        }
    }
}