import { http } from "@/interceptor/http.interceptor";
import { ApiResponse, Crypto, CryptoDataChart, CryptoResponse } from "@/models/types";
import { Module } from "vuex"
import { RootStore } from ".."

export interface ExternalCryptoModule {
    cryptoCoins: CryptoResponse;
    cryptoData: CryptoDataChart;
    crypto: Crypto;
}

const initExternalCryptoModule: ExternalCryptoModule = {
    cryptoCoins: {} as CryptoResponse,
    cryptoData: {} as CryptoDataChart,
    crypto: {} as Crypto
}

export const externalCrypto: Module<ExternalCryptoModule, RootStore> = {
    namespaced: true,
    state: {
        ...initExternalCryptoModule
    },
    getters: {
        cryptoCoins: state => state.cryptoCoins,
        cryptoData: state => state.cryptoData || {} as CryptoDataChart
    },
    actions: {
        getCryptoCoins({commit, rootState}) {
            http<ApiResponse<CryptoResponse>>({method: 'get', url: `/external/assets/EUR`, loader: false}).then(cryptoCoinsResponse => {
                commit('setCryptoCoins', cryptoCoinsResponse.data)
                const newBalance = rootState.balance.map(bal => {
                    const cryptoBal = Object.values(cryptoCoinsResponse.data.data ?? {}).find(value => bal.sign === value.sign)
                    return {
                        ...bal,
                        conversionRate: cryptoBal?.price ?? bal.conversionRate
                    }
                })
                commit('setAssetConversion', newBalance, {root: true})
            })
            .catch((err) => {
                console.error(err)
            });
        },
        getCryptoData({commit}, {cryptoId, interval}) {
            http<ApiResponse<CryptoDataChart>>({method: 'post', url: `/external/trade/${cryptoId}/EUR?interval=${interval}`})
            .then(cryptoDataResponse => {
                commit('setCryptoData', cryptoDataResponse.data)
            })
            .catch((err) => {
                commit('setCryptoData', {})
                console.error(err)
            });
        },
    },
    mutations: {
        setCryptoCoins: (state, payload) => {
            state.cryptoCoins = payload
        },
        setCryptoData: (state, payload) => {
            state.cryptoData = payload
        },
        resetStore: (state) => {
            Object.assign(state, initExternalCryptoModule)
        },
    },
} 