import { Module } from "vuex";
import { RootStore } from "..";

export interface UIModule {
    alertMessage: AlertMessage[];
    loaderCounter: number;
}

export type AlertMessage = {
    title: string;
    message: string; 
    type: 'success' | 'error' | 'warning' | 'info' | 'question';
}

export const ui: Module<UIModule, RootStore> = {
    namespaced: true,
    state: {
        alertMessage: [],
        loaderCounter: 0
    },
    getters: {
        alertMessage: state => state.alertMessage,
        showLoader: state => state.loaderCounter > 0
    },
    mutations: {
        resetStore: (state) => {
            state.alertMessage = []
            state.loaderCounter = 0
        },
        setAlertMessage: (state, payload: AlertMessage) => {
            state.alertMessage = [...state.alertMessage, payload]
        },
        removeAlertMessage: (state, payload: number) => {
            state.alertMessage.splice(payload, 1)
            state.alertMessage = [...state.alertMessage]
        },
        showLoader: (state, payload: boolean) => {
            state.loaderCounter = payload? 
                (state.loaderCounter + 1) : 
                (state.loaderCounter == 0? state.loaderCounter : (state.loaderCounter - 1))
        },
    }
}