import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderSlot as _renderSlot, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6b540cab"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "receipt" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_avatar = _resolveComponent("ion-avatar")!
  const _component_ion_item_divider = _resolveComponent("ion-item-divider")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_footer = _resolveComponent("ion-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_title, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_text, { color: "dark" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.title), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_ion_row, { class: "row-modal-content" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, { size: "12" }, {
                default: _withCtx(() => [
                  (_ctx.operationMessage)
                    ? (_openBlock(), _createBlock(_component_ion_item, {
                        key: 0,
                        lines: "none",
                        class: "mb-10 mt-10"
                      }, {
                        default: _withCtx(() => [
                          (_ctx.status === 'ERROR')
                            ? (_openBlock(), _createBlock(_component_ion_icon, {
                                key: 0,
                                color: "danger",
                                icon: _ctx.icons.closeCircle,
                                slot: "start"
                              }, null, 8, ["icon"]))
                            : (_openBlock(), _createBlock(_component_ion_icon, {
                                key: 1,
                                color: "success",
                                icon: _ctx.icons.checkmarkCircle,
                                slot: "start"
                              }, null, 8, ["icon"])),
                          _createVNode(_component_ion_label, { style: {"word-break":"break-word","white-space":"pre-wrap"} }, {
                            default: _withCtx(() => [
                              _createElementVNode("h2", null, [
                                _createElementVNode("b", null, _toDisplayString(_ctx.operationMessage), 1)
                              ]),
                              (_ctx.alertMessage)
                                ? (_openBlock(), _createElementBlock("h5", _hoisted_2, _toDisplayString(_ctx.alertMessage), 1))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.txDetailAsset)
                    ? (_openBlock(), _createBlock(_component_ion_item, {
                        key: 1,
                        lines: "none"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_avatar, null, {
                            default: _withCtx(() => [
                              _createElementVNode("img", {
                                onerror: "this.onerror=null; this.src='/assets/images/logo-placeholder.png'",
                                src: '/assets/images/logo-' + _ctx.txDetailAsset.replace('#', '').toLowerCase() + '.png'
                              }, null, 8, _hoisted_3)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.transactionListTitle)
                    ? (_openBlock(), _createBlock(_component_ion_item_divider, { key: 2 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.transactionListTitle), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_ion_list, { class: "ion-margin-top ion-margin-end" }, {
                    default: _withCtx(() => [
                      (_ctx.idTicket)
                        ? (_openBlock(), _createBlock(_component_ion_item, { key: 0 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_note, {
                                slot: "start",
                                color: "dark"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t("Transaction ID")), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_label, {
                                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openTooltip(_ctx.idTicket))),
                                class: "ion-text-right cursor-pointer"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.idTicket), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_icon, {
                                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onCopyToClipboard(_ctx.idTicket))),
                                icon: _ctx.icons.copyOutline,
                                slot: "end"
                              }, null, 8, ["icon"])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.buyAssetName)
                        ? (_openBlock(), _createBlock(_component_ion_item, { key: 1 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t("Asset")), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_note, {
                                color: "dark",
                                slot: "end"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.buyAssetName), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.buyAssetQuantity)
                        ? (_openBlock(), _createBlock(_component_ion_item, { key: 2 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t("Asset amount")), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_note, {
                                color: "dark",
                                slot: "end"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.buyAssetQuantity), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.buyPriceAmount)
                        ? (_openBlock(), _createBlock(_component_ion_item, { key: 3 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t("Asset price")), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_note, {
                                color: "dark",
                                slot: "end"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.buyPriceAmount), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.buyFeeAmount)
                        ? (_openBlock(), _createBlock(_component_ion_item, { key: 4 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t("Fee")), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_note, {
                                color: "dark",
                                slot: "end"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.buyFeeAmount), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.buyTotalPaid)
                        ? (_openBlock(), _createBlock(_component_ion_item, { key: 5 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t("Total amount")), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_note, {
                                color: "dark",
                                slot: "end"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.buyTotalPaid), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.changeAssetNameSell)
                        ? (_openBlock(), _createBlock(_component_ion_item, { key: 6 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t("Selled asset")), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_note, {
                                color: "dark",
                                slot: "end"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.changeAssetNameSell), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.changeAssetSellQuantity)
                        ? (_openBlock(), _createBlock(_component_ion_item, { key: 7 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t("Selled asset quantity")), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_note, {
                                color: "dark",
                                slot: "end"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.changeAssetSellQuantity), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.changeAssetNameAccepted)
                        ? (_openBlock(), _createBlock(_component_ion_item, { key: 8 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t("Accepted asset")), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_note, {
                                color: "dark",
                                slot: "end"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.changeAssetNameAccepted), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.changeTotalAmount)
                        ? (_openBlock(), _createBlock(_component_ion_item, { key: 9 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t("Accepted asset quantity")), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_note, {
                                color: "dark",
                                slot: "end"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.changeTotalAmount), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.changeTotalFee)
                        ? (_openBlock(), _createBlock(_component_ion_item, { key: 10 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t("Fee")), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_note, {
                                color: "dark",
                                slot: "end"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.changeTotalFee), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.withdrawAssetName)
                        ? (_openBlock(), _createBlock(_component_ion_item, { key: 11 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t("Asset withdrawn")), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_note, {
                                color: "dark",
                                slot: "end"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.withdrawAssetName), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.withdrawAmount)
                        ? (_openBlock(), _createBlock(_component_ion_item, { key: 12 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t("Withdrawn quantity")), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_note, {
                                color: "dark",
                                slot: "end"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.withdrawAmount), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.withdrawFee)
                        ? (_openBlock(), _createBlock(_component_ion_item, { key: 13 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t("Fee")), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_note, {
                                color: "dark",
                                slot: "end"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.withdrawFee), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.withdrawTotal)
                        ? (_openBlock(), _createBlock(_component_ion_item, { key: 14 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t("Total withdrawn")), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_note, {
                                color: "dark",
                                slot: "end"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.withdrawTotal), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.abortAssetName)
                        ? (_openBlock(), _createBlock(_component_ion_item, { key: 15 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t("Aborted asset")), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_note, {
                                color: "dark",
                                slot: "end"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.abortAssetName), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.abortAssetAmount)
                        ? (_openBlock(), _createBlock(_component_ion_item, { key: 16 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t("Aborted amount")), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_note, {
                                color: "dark",
                                slot: "end"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.abortAssetAmount), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.abortFee)
                        ? (_openBlock(), _createBlock(_component_ion_item, { key: 17 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t("Fee")), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_note, {
                                color: "dark",
                                slot: "end"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.abortFee), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.operationStatus)
                        ? (_openBlock(), _createBlock(_component_ion_item, {
                            key: 18,
                            lines: "none"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.date), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_note, {
                                color: _ctx.operationMessageType,
                                slot: "end"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("b", null, _toDisplayString(_ctx.operationStatus), 1)
                                ]),
                                _: 1
                              }, 8, ["color"])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.txDetailType)
                        ? (_openBlock(), _createBlock(_component_ion_item, { key: 19 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_note, {
                                color: "dark",
                                slot: "start"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t("Operation type")), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_label, { class: "ion-text-right" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.txDetailType), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.txDetailAsset)
                        ? (_openBlock(), _createBlock(_component_ion_item, { key: 20 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_note, {
                                color: "dark",
                                slot: "start"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t("Asset")), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_label, { class: "ion-text-right" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.txDetailAsset), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.txDetailAssetQuantity)
                        ? (_openBlock(), _createBlock(_component_ion_item, { key: 21 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_note, {
                                color: "dark",
                                slot: "start"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t("Asset amount")), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_label, { class: "ion-text-right" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.txDetailAssetQuantity), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.txDetailCreatedAt)
                        ? (_openBlock(), _createBlock(_component_ion_item, { key: 22 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_note, {
                                color: "dark",
                                slot: "start"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t("Created At")), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_label, { class: "ion-text-right" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.txDetailCreatedAt), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }),
                  _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                ]),
                _: 3
              })
            ]),
            _: 3
          })
        ])
      ]),
      _: 3
    }),
    _createVNode(_component_ion_footer, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_button, {
              slot: "end",
              type: "button",
              onClick: _ctx.onClose,
              class: "ion-margin-vertical mr-10",
              fill: "outline",
              shape: "round",
              size: "small",
              color: "dark"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t("Close window")), 1)
              ]),
              _: 1
            }, 8, ["onClick"]),
            _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
          ]),
          _: 3
        })
      ]),
      _: 3
    })
  ], 64))
}